/* eslint-disable no-prototype-builtins */
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keycloakService } from "services/authService";
import qs from "qs";
import { useKeycloak } from "@react-keycloak/web";
import { fetchClientConfig } from "services/input-core/clientConfig/get-client-config";

const LOGIN_ENDPOINT = "/token?client_id=account";

type AuthContextData = {
  signIn: (email: string, password: string) => Promise<void>;
  logout: (() => void) | undefined;
  hasRealmRole: (args: any) => boolean;
  hasResourceRole: (args: any) => boolean;
  getUserInfo: () => Promise<any>;
  userToken: UserToken | undefined;
  isAuthenticate: boolean;
  userInfo: any;
  isLoadingClientConfig: boolean;
  decoded: any;
};

type UserToken = {
  token: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext({} as AuthContextData);

export function signOut() {
  const navigate = useNavigate();
  localStorage.clear();
  sessionStorage.clear();
  keycloakService.defaults.headers["Authorization"] = undefined;
  navigate("/auth/login");
}

export const AuthProvider: React.FC = ({ children }: AuthProviderProps) => {
  const [userToken, setUserToken] = useState<UserToken>();
  const [isAuthenticate, setAuthenticate] = useState(false);
  const [isLoadingClientConfig, setIsLoadingClientConfig] = useState(false);
  const [userInfo, setUserInfo] = useState({} as any);
  const navigate = useNavigate();
  const [decoded, setDecoded] = useState<any>();
  const { keycloak, initialized } = useKeycloak();
  const subscription = sessionStorage.getItem("subscription");

  useEffect(() => {
    async function handleGetUserInfo() {
      await getUserInfo();
    }

    if (initialized && keycloak?.authenticated) {
      const token = localStorage.getItem("token");

      if (token) {
        keycloakService.defaults.headers["Authorization"] = `Bearer ${token}`;
        setAuthenticate(true);
        handleGetUserInfo();
      }
    }
  }, [keycloak?.authenticated, initialized]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingClientConfig(true);
      if (subscription) {
        fetchClientConfig(subscription);
        setIsLoadingClientConfig(false);
      }
    };

    fetchData().catch((error) => console.error(error));
  }, [subscription]);

  async function getUserInfo() {
    try {
      await keycloak?.loadUserInfo();
      const userInfoObject: any = keycloak?.userInfo;
      setUserInfo(userInfoObject);
      sessionStorage.setItem("username", userInfoObject?.name);
      return keycloak?.userInfo;
    } catch (error) {
      logout();
    }
  }

  async function signIn(email: string, password: string) {
    const data = qs.stringify({
      client_id: process.env.REACT_APP_API_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_AUTH_CLIENT_SECRET,
      username: email,
      password: password,
      grant_type: "password",
    });

    const loginResponse = await keycloakService.post(LOGIN_ENDPOINT, data);
    const { access_token, refresh_token } = loginResponse.data;

    localStorage.setItem("token", access_token);
    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("refreshToken", refresh_token);
    setDecoded(keycloak?.tokenParsed);

    keycloakService.defaults.headers["Authorization"] = `Bearer ${access_token}`;
    setUserToken(access_token);

    setAuthenticate(true);

    await keycloak
      ?.init({
        token: access_token,
        refreshToken: refresh_token,
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        if (authenticated) return getUserInfo();
      })
      .then((userInfo: any) => {
        if (userInfo?.jsonCustomAttributes || userInfo?.customAttributes?.supplierCode)
          navigate("../app/dashboard");
        else navigate("../blocked");
      });
  }

  function logout() {
    setAuthenticate(false);
    localStorage.clear();
    sessionStorage.clear();
    keycloakService.defaults.headers["Authorization"] = undefined;
    keycloak.logout({ redirectUri: window.location.origin });
  }

  function hasRealmRole(roles: any) {
    for (const role of roles) {
      if (keycloak?.hasRealmRole(role)) {
        return true;
      }
    }
    return false;
  }

  function hasResourceRole(roles: any) {
    for (const role of roles) {
      if (keycloak?.hasResourceRole(role, "uverify")) {
        return true;
      }
    }
    return false;
  }

  const value = {
    signIn,
    isAuthenticate,
    logout,
    userToken,
    userInfo,
    getUserInfo,
    hasRealmRole,
    hasResourceRole,
    isLoadingClientConfig,
    decoded,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextData {
  const context: any = useContext(AuthContext);

  return context;
}
